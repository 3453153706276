@import './assets/fonts/fonts.css';
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #192333;
}

#root {
  display: flex;
  justify-content: center;
}

.landing {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  background: #F6F6F6;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
}

h1 {
  font-family: yandexSansTextMedium;
  font-size: 44px;
  line-height: 72px;
  margin-bottom: 25px;
}

h2 {
  font-family: yandexSansTextRegular;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 20px;
}

h3 {
  font-family: yandexSansTextRegular;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 15px;
}

.ssmm-video-wrap {
  position: relative;
  height: 0;
  overflow: hidden;
  width: 100%;
  padding-bottom: 56.25%;
}

.ssmm-video-wrap>img {
  width: 100%;
}

.ssmm-video-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}