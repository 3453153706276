@font-face {
    font-family: yandexSansTextBold;
    src: url('./YandexSansTextBold/YandexSansTextBold.eot');
    src: url('./YandexSansTextBold/YandexSansTextBold.woff') format('woff'), url('./YandexSansTextBold/YandexSansTextBold.ttf') format('truetype')
}

@font-face {
    font-family: yandexSansTextMedium;
    src: url('./YandexSansTextMedium/YandexSansTextMedium.eot');
    src: url('./YandexSansTextMedium/YandexSansTextMedium.woff') format('woff'), url('./YandexSansTextMedium/YandexSansTextMedium.ttf') format('truetype')
}

@font-face {
    font-family: yandexSansTextRegular;
    src: url('./YandexSansTextRegular/YandexSansTextRegular.eot');
    src: url('./YandexSansTextRegular/YandexSansTextRegular.woff') format('woff'), url('./YandexSansTextRegular/YandexSansTextRegular.ttf') format('truetype')
}